const ApiUrl = process.env.REACT_APP_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const GET_OPS_RESULTING_DATA_BY_ID = (id) =>
  UrlParamsReplace("/customer/:id/get-ops-checklist-data", { id });

export const ADD_OPS_RESULT_DATA_TO_ZOHO = (id) =>
  UrlParamsReplace("/save-ops-data-to-zoho/:id", { id });
export const GET_CUSTOMER = (id) => UrlParamsReplace("/customer/:id", { id });

export const UPDATE_QUESTION = (id) =>
  UrlParamsReplace("/question/:id", { id });

export const UPDATE_INVOICE = (customerId) =>
  UrlParamsReplace("/invoice/:customerId/bulk", { customerId });

export const UPDATE_PROJECT_STATUS = (customerId) =>
  UrlParamsReplace("/customer/:customerId/status", { customerId });

export const UPDATE_CUSTOM_NOTE = (customerId) =>
  UrlParamsReplace("/custom-note/:customerId/bulk", { customerId });

export const UPDATE_OPEN_QUESTION = (customerId) =>
  UrlParamsReplace("/open-question/:customerId/bulk", { customerId });

export const UPDATE_TRANSITION = (customerId) =>
  UrlParamsReplace("/transition/:customerId/bulk", { customerId });

export const GET_CUSTOMERS_LIST = (
  page,
  size,
  filter1,
  filter2,
  searchQuery,
  order = "DESC",
  currentTab
) => {
  let urlString = UrlParamsReplace(
    `/customer?page=${page}&size=${size}&order=${order}&project_status=${currentTab}`,
    {}
  );
  if (filter1 && filter1?.length > 0 && filter2 !== null) {
    filter1.forEach((k) => {
      urlString += `&${k}=${filter2}`;
    });
  }

  if (searchQuery) {
    urlString += `&searchToken=${searchQuery}`;
  }
  return urlString;
};

export const DELETE_OPEN_QUESTION = (customerId, questionId) => {
  console.log("DELETE_OPEN_QUESTION called with:", customerId, questionId);
  return UrlParamsReplace(`/open-question/${customerId}/${questionId}`, {});
};

export const DELETE_TRANSITION = (customerId, questionId) =>
  UrlParamsReplace(`/transition/:customerId/:questionId  `, {
    customerId,
    questionId
  });

export const UPDATE_INVOICE_PRODUCTS = (zcPoId) =>
  UrlParamsReplace("/invoice/:zcPoId", { zcPoId });

export const UPDATE_CUSTOM_NOTES = (zcPoId) =>
  UrlParamsReplace("/custom-note/:zcPoId", { zcPoId });

export const GET_USER_BY_ID = (id) => {
  return `https://fvbackend.kasawalkthrough.com/api/auth/get-user/${id}`;
};

export const UPDATE_ROOM_NAME_IN_ZOHO = () => {
  return "https://proposal-backend.kasawalkthrough.com/api/update-room-in-zoho";
};

export const UPLOAD_IMAGE = (customerId) =>
  "https://fvbackend.kasawalkthrough.com/api/upload-file";

// New Apis
export const GET_CUSTOMERS = (startDate, endDate) => {
  if (startDate && endDate) {
    return UrlParamsReplace(
      `/customer?fwStartDate=${startDate}&fwEndDate=${endDate}`,
      {}
    );
  } else {
    return UrlParamsReplace(`/customer`, {});
  }
};
export const UPDATE_CUSTOMER = (customerId) =>
  UrlParamsReplace("/customer/:customerId", { customerId });

export const ADD_CLONE_PROJECT = (customerId) =>
  UrlParamsReplace("/customer/:customerId/clone", { customerId });

export const DELETE_CLONE_PROJECT = (parentId, customerId) =>
  UrlParamsReplace("/customer/:parentId/:customerId/delete", {
    parentId,
    customerId
  });
export const BULK_DELETE_CLONE_PROJECT = (parentId) =>
  UrlParamsReplace("/customer/:parentId/bulk-delete", {
    parentId
  });

export const UPDATE_OPS_SCHEDULING = (parentId, customerId) =>
  UrlParamsReplace("/customer/:parentId/:customerId/udpate-clone-customer", {
    parentId,
    customerId
  });
