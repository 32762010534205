export const QuestionFormField = [
  [
    {
      question: "Project Type",
      key: "Project Type",
      type: "DROPDOWN",
      option: ["Refinishing", "Installation", "Repair"]
    },
    {
      question: "estimated_start_date",
      key: "estimated_start_date",
      type: "DATE"
    },
    {
      question: "project_size",
      key: "project_size",
      type: "TEXT",
      isMultiLine: false
    }
  ]
];

export const projectTypeOption = [
  "All",
  "-None-",
  "Sanding and Refinishing",
  "Hardwood Installation",
  "Laminate Installation",
  "Install Wood or Stone Laminate Flooring",
  "Install or Completely Replace Wood Flooring",
  "Hardwood Floor Refinishing",
  "Refinish Wood Flooring",
  "Unknown",
  "Uknown",
  "Installation",
  "LVT"
];
