import React, { useState } from "react";
import { TextField } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import styled from "@emotion/styled";
import DropdownField from "../Fields/dropdown";
import ProjectSizePopover from "./ProjectSizePopover";

const NoteTextField = styled(TextField)(({ width }) => ({
  "&.MuiTextField-root": {
    "& .MuiInputBase-input": {
      padding: "14px",
      fontSize: "13px"
      // textAlign: "center"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px"
    },
    "& .css-lccy1c-MuiGrid-root": {
      paddingRight: "0"
    },
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "6px",
      paddingTopL: "3px",
      backgroundColor: "white",
      borderRadius: "10px",
      textAlign: "center"
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "0"
    }
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "10px",
    width: width ? width : "",
    "& fieldset>legend": {
      fontSize: "10px"
    }
  }
}));

const FilterControls = ({
  filter,
  projectTypeOption,
  values,
  setValues,
  handleFilterChange,
  handleClearFilters,
  handleMinSizeChange,
  handleMaxSizeChange,
  handleBackToWeekView,
  handleSearchChange, // New prop for handling search query change
  view
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleProjectSizeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProjectSizeClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    // setSearchQuery(value);
    handleFilterChange("searchQuery", value); // Pass the search query to the parent component
  };

  return (
    <div className="flex space-x-4 w-[90%]">
      <div className="flex justify-between ">
        <button
          onClick={handleBackToWeekView}
          className={`bg-gray-800 text-white text-[14px] px-3 py-2 rounded-xl ${
            view === "week" ? "hidden" : "block"
          }`}
        >
          Back
        </button>
      </div>

      <div className="">
        <NoteTextField
          label="Search"
          value={filter?.searchQuery || ""}
          onChange={handleSearchInputChange}
          variant="outlined"
          size="small"
          width="200px"
        />
      </div>
      <div className="w-[200px]">
        <DropdownField
          question={"Project Type"}
          value={filter.projectType}
          options={projectTypeOption}
          handleChangeDropdown={(e) =>
            handleFilterChange("projectType", e.target.value)
          }
          width="150px"
        />
      </div>
      <div className="">
        <NoteTextField
          label="Project Size"
          onClick={handleProjectSizeClick}
          value={
            filter.projectSizeRange
              ? `$${Math.round(filter.projectSizeRange[0]) || 0} - $${
                  Math.round(filter.projectSizeRange[1]) || 0
                }`
              : ""
          }
          variant="outlined"
          size="small"
          readOnly
          style={{ cursor: "pointer" }}
          width="150px"
        />
        <ProjectSizePopover
          anchorEl={anchorEl}
          open={open}
          handleClose={handleProjectSizeClose}
          projectSizeRange={filter.projectSizeRange || [0, 0]}
          handleMinSizeChange={handleMinSizeChange}
          handleMaxSizeChange={handleMaxSizeChange}
        />
      </div>
      <div className="w-[300px] flex justify-between space-x-2">
        <DatePicker
          value={values}
          onChange={setValues}
          range
          numberOfMonths={2}
          render={
            <NoteTextField
              label="Est Start Date"
              fullWidth
              variant="outlined"
              size="small"
              readOnly
              width="200px"
            />
          }
        />
        <button
          onClick={handleClearFilters}
          className="bg-gray-800 text-white text-[14px] px-3 py-2 rounded-xl"
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default FilterControls;
