import React, { useRef } from "react";
import DayColumnsLayout from "./DaysColumnsLayout";
import TeamDayColumns from "./TeamDaysColumns";
import ResizableDiv from "../UI/ResizableDiv";
import SlickSlider from "../SlickSlider";
import DayViewSlickSlider from "../DayViewSlickSlider";

const MainComponent = ({
  selectedWeek,
  startDate,
  startWeek,
  weekDayProject,
  moveCard,
  handleDoubleClick,
  extractWeekNumber,
  getFiscalWeekStartDate,
  filterSearch = "",
  dayViewSettings,
  handlePrevious,
  handleNext,
  setProjects,
  projects,
  fetchProjects,
  setOpen,
  handleEditChange
}) => {
  console.log("mainComponent", projects);
  const startX = useRef(0); // To store the start X position of the swipe
  const endX = useRef(0);
  const handleMouseDown = (e) => {
    startX.current = e.clientX;
  };

  const handleMouseUp = (e) => {
    endX.current = e.clientX;
    detectSwipeDirection();
  };

  const detectSwipeDirection = () => {
    const deltaX = endX.current - startX.current;
    if (deltaX > 50) {
      // Right swipe (left to right)
      handlePrevious();
    } else if (deltaX < -50) {
      // Left swipe (right to left)
      handleNext();
    }
  };
  return (
    <DayViewSlickSlider
      {...dayViewSettings}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
    >
      <div>
        <div className="flex flex-col h-[88vh] space-y-2">
          {/* Top Part: Day Columns */}
          <DayColumnsLayout
            selectedWeek={selectedWeek}
            startDate={startDate}
            startWeek={startWeek}
            weekDayProject={weekDayProject}
            moveCard={moveCard}
            handleDoubleClick={handleDoubleClick}
            extractWeekNumber={extractWeekNumber}
            getFiscalWeekStartDate={getFiscalWeekStartDate}
            fetchProjects={fetchProjects}
          />
          {/* </ResizableDiv> */}

          {/* Bottom Part: Team Names with Day Columns */}

          <TeamDayColumns
            teams={[
              "Peter",
              "Peter 2",
              "Fabiano",
              "Fabiano 2",
              "Nery",
              "Nery 2",
              "Jefferson",
              "Jefferson 2",
              "Jader",
              "Jader 2",
              "Jovany",
              "Jovany 2",
              "Victor"
            ]}
            selectedWeek={selectedWeek}
            startDate={startDate}
            startWeek={startWeek}
            weekDayProject={weekDayProject}
            moveCard={moveCard}
            handleDoubleClick={handleDoubleClick}
            extractWeekNumber={extractWeekNumber}
            getFiscalWeekStartDate={getFiscalWeekStartDate}
            filterSearch={filterSearch}
            setProjects={setProjects}
            projects={projects}
            fetchProjects={fetchProjects}
            setOpen={setOpen}
            handleEditChange={handleEditChange}
          />
        </div>
      </div>
    </DayViewSlickSlider>
  );
};

export default MainComponent;
