import React, { useState, useRef, useEffect } from "react";
import { useDrop } from "react-dnd";
import DraggableCard from "./DraggableCard";
import WeekPopup from "./WeekPopup";
import NoteField from "../Fields/NoteField";

const calculateTotals = (projects) => {
  const totalProjectSize = projects?.reduce(
    (sum, project) => sum + Number(project["project_size"] || 0),
    0
  );
  const totalPaidAmount = projects?.reduce(
    (sum, project) => sum + Number(project["paid_amount"] || 0),
    0
  );
  const totalDueAmount = projects?.reduce(
    (sum, project) => sum + Number(project["due_amount"] || 0),
    0
  );

  return { totalProjectSize, totalPaidAmount, totalDueAmount };
};

const formatTitleWithComma = (title) => {
  return title?.replace(/(\d{2} \w{3}) (\d{4})/, "$1, $2");
};

const WeeklyProjectColumn = ({
  column,
  moveCard,
  backlog,
  onDoubleClick,
  setView,
  view,
  onWeekTitleClick,
  selectedWeek,
  setProjects,
  projects,
  fetchProjects
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ["PARENT_CARD", "CARD"], // Accept multiple card types
    // drop: (item) => {
    //   if (item.type === "PARENT_CARD") {
    //     // Logic for PARENT_CARD type
    //     moveCard(item, "unassigned", selectedWeek);
    //   }
    // },
    hover: (item, monitor) => {
      if (item.type === "CHILD_CARD") {
        monitor.getItemType(); // No action, just prevent any further actions
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  console.log(column, "column--");

  const { totalProjectSize, totalPaidAmount, totalDueAmount } = calculateTotals(
    column.projects
  );

  const [weekPopupOpen, setWeekPopupOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  // Filter projects based on the search query
  const filteredProjects = column?.projects?.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div
        ref={drop}
        className={`relative flex-1 bg-[#f1f2f4] text-[12px] text-black px-1 py-4 ${
          backlog ? "ml-0 " : "mx-1 my-2 rounded-xl"
        } shadow-md ${backlog && "h-full !bg-[#ac4b79] "} ${
          isOver ? "border-2 border-dashed border-blue-500" : ""
        }`}
        style={{ minWidth: "200px", flexBasis: "0" }}
      >
        <div className="flex flex-col h-full">
          <NoteField
            question={"Search"}
            value={searchQuery}
            handleNoteBlur={(e) => setSearchQuery(e.target.value)}
            type="text"
            event="onChange"
          />
          <div className="flex-1 ">
            <div className="py-2">
              <h2
                className={`cursor-pointer absolute top-2 z-50  ${
                  backlog
                    ? " text-[16px] text-black bg-white "
                    : "backdrop-blur-xl bg-opacity-50 text-[14px]"
                } font-bold px-3  rounded-lg`}
              >
                FW - {formatTitleWithComma(selectedWeek)}
              </h2>
            </div>
            <div
              className="overflow-y-auto"
              style={{
                height: backlog ? "calc(100vh - 140px)" : "calc(100vh - 250px)"
              }}
            >
              {filteredProjects.map((project) => (
                <DraggableCard
                  key={project?.id}
                  id={project?.id}
                  customerName={project["name"]}
                  projectType={project["project_type"]}
                  projectSize={project["project_size"]}
                  estimatedStartDate={project["estimated_start_date"]}
                  onDoubleClick={onDoubleClick}
                  state={project["state"]}
                  delivery_status={project["delivery_status"]}
                  setProjects={setProjects}
                  projects={projects}
                  project={project}
                  dragType={"PARENT_CARD"}
                  fetchProjects={fetchProjects}
                />
              ))}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full p-2 bg-gray-800 text-white">
            <div className="flex flex-col space-y-1 text-[13px] font-semibold">
              <div className="flex justify-between items-center border-b border-gray-700 pb-1">
                <span>project_size:</span>
                <span className="text-lg font-bold">
                  {totalProjectSize?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-gray-700 pb-1">
                <span className="text-green-300">Paid:</span>
                <span className="text-green-500 font-bold">
                  {totalPaidAmount?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-red-300">Due:</span>
                <span className="text-red-500 font-bold">
                  {totalDueAmount?.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <WeekPopup
        open={weekPopupOpen}
        handleClose={handleWeekPopupClose}
        weekProjects={column.projects}
        fiscalWeekTitle={column?.title}
      /> */}
    </>
  );
};

export default WeeklyProjectColumn;
