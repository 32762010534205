import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Avatar,
  IconButton,
} from "@mui/material";
import DropdownField from "../../Fields/dropdown";
import CustomDatePicker from "../../Fields/customDatePicker";
import moment from "moment";
import BusinessIcon from "@mui/icons-material/Business";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Groups2Icon from "@mui/icons-material/Groups2";
import NoteField from "../../Fields/NoteField";

const EditCardDialog = ({
  editCard,
  open,
  handleEditClose,
  handleEditChange,
  handleDateChange,
}) => {
  const options = ["Not Yet Started", "In Process", "Complete"];
  const options2 = ["Tentative", "Booked"];

  const teams =
    editCard?.clones?.length > 0
      ? Array.from(
          new Set(
            editCard.clones.map((pr) => pr?.assigned_team).filter(Boolean) // Remove any undefined or null values
          )
        ).join(", ")
      : editCard?.parent_id
      ? editCard?.assigned_team
      : "";

  const summaryItems = [
    {
      icon: <BusinessIcon />,
      label: "Project Type",
      value: editCard["project_type"] || "-",
    },
    {
      icon: <AssessmentIcon />,
      label: "Project Size",
      value: editCard["project_size"] || "-",
    },
    {
      icon: <AttachMoneyIcon />,
      label: "Paid",
      value: editCard["paid_amount"] || "-",
    },
    {
      icon: <MonetizationOnIcon />,
      label: "Due",
      value: editCard["due_amount"] || "-",
    },
    {
      icon: <LocationOnIcon />,
      label: "City",
      value: editCard["city"] || "-",
      col: 6,
    },
    {
      icon: <LocationOnIcon />,
      label: "State",
      value: editCard["state"] || "-",
      col: 6,
    },
    {
      icon: <CalendarMonthIcon />,
      label: "Actual Start Date",
      value: editCard["actual_start_date"] || "-",
    },
    {
      icon: <Groups2Icon />,
      label: "Teams",
      value: teams || "-", // Show teams or '-' if empty
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleEditClose}
      PaperProps={{
        style: { backgroundColor: "#ffffff", borderRadius: "16px" },
      }}
      maxWidth="md"
      fullWidth
    >
      <Box
        sx={{
          padding: "16px",
          backgroundColor: "#f1f2f4",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          borderBottom: "1px solid #ccc",
          position: "relative",
        }}
      >
        {editCard["Customer Name"]}
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            pb: "10px",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            {/* Left Section */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <DropdownField
                      question={"Status"}
                      value={editCard["Status"]}
                      options={options}
                      handleChangeDropdown={(e) => {
                        handleEditChange({
                          target: { name: "Status", value: e.target.value },
                          ids: {
                            id: editCard?.id,
                            parent_id: editCard?.parent_id,
                          },
                          type: "EditCard",
                        });
                      }}
                      selectedBackgroundColor="red"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <CustomDatePicker
                      question={"Est. Start Date"}
                      value={moment(
                        editCard["estimated_start_date"],
                        "DD/MM/YYYY"
                      )}
                      handleNoteBlur={(date) => {
                        handleDateChange(moment(date?.$d).format("YYYY-MM-DD"));
                      }}
                      id="date-picker"
                      closeOnSelect={true}
                    />
                  </Box>
                </Grid>
                {editCard?.parent_id && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box mb={2}>
                        <DropdownField
                          question={"OPS Scheduling Status"}
                          value={editCard["ops_scheduling_status"]}
                          options={options2}
                          handleChangeDropdown={(e) =>
                            handleEditChange({
                              target: {
                                name: "ops_scheduling_status",
                                value: e.target.value,
                              },
                              ids: {
                                id: editCard?.id,
                                parent_id: editCard?.parent_id,
                              },
                              type: "EditCard",
                            })
                          }
                          selectedBackgroundColor="red"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box mb={2}>
                        <NoteField
                          question={"OPS Task Note"}
                          value={editCard["ops_task_note"]}
                          type="text"
                          handleNoteBlur={(e) =>
                            handleEditChange({
                              target: {
                                name: "ops_task_note",
                                value: e.target.value,
                              },
                              ids: {
                                id: editCard?.id,
                                parent_id: editCard?.parent_id,
                              },
                              type: "EditCard",
                            })
                          }
                          maxRows={3}
                          minRows={2}
                          multiline
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            {/* Divider */}
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            {/* Right Section */}
            <Grid container spacing={2} xs={12} md={5}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2, ml: 2 }}>
                Summary
              </Typography>
              {summaryItems.map((item, index) => (
                <Grid item xs={item?.col || 12} key={index}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                      p: 1.5,
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          item?.label === "Due"
                            ? "red"
                            : item?.label === "Paid"
                            ? "green"
                            : "primary.main",
                        mr: 1,
                        width: 30,
                        height: 30,
                      }}
                    >
                      {item.icon}
                    </Avatar>
                    <Box>
                      <Typography variant="body2" gutterBottom>
                        <strong>{item.label}:</strong> {item.value}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditCardDialog;
