import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import {
  getColorForButton,
  greenWithOP,
  redWithOP,
} from "../utils/CustomColor";
import {
  addCloneProject,
  deleteCloneProject,
  deleteCustomer,
} from "./services/finance.service";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

const DraggableCard = ({
  id,
  customerName,
  projectType,
  estimatedStartDate,
  onDoubleClick,
  projectSize,
  state,
  view = "week",
  delivery_status,
  ops_overall_status,
  project,

  projects,
  dragType,
  fetchProjects,
  setOpen,
  handleEditChange,
}) => {
  console.log("project11", project, project?.FW);

  const projectRef = useRef(project);
  // Keep the ref updated with the latest project value
  useEffect(() => {
    projectRef.current = project;
  }, [project]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: dragType || "CARD",
    item: () => {
      // This function is called right at the start of the drag
      // It captures the most recent values of `projectRef.current`
      return {
        id,
        parent_id: projectRef.current?.parent_id,
        type: dragType,
        FW: projectRef.current?.FW,
        project: projectRef.current,
      };
    },

    end: (item) => {
      console.log("item--", item);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const formattedDate = estimatedStartDate
    ? moment(estimatedStartDate, "DD/MM/YYYY").format("MMM Do")
    : "No Date";

  const handleOverAllCall = () => {
    let url = `https://ops-checklist.kasawalkthrough.com/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H?id=${project?.zc_po_id}`;
    window.open(url, "_blank");
  };

  const handleClone = async () => {
    setOpen(true);
    // Start with the current actual start date
    let currentStartDate = moment(project.actual_start_date, "YYYY-MM-DD");
    let newStartDate = currentStartDate.add(1, "days"); // Increment date by 1 day
    let newStartDay = newStartDate.format("ddd"); // Get the day of the week (e.g., "Thu")

    // Check if the new date is Sunday (0 in moment.js)
    if (newStartDate.day() === 0) {
      newStartDate = newStartDate.add(1, "days"); // Skip Sunday
      newStartDay = newStartDate.format("ddd"); // Update day to the next day
    }

    // Calculate the start of the week for comparison
    const weekStartDate = newStartDate.clone().startOf("isoWeek"); // Start of the ISO week (Monday)

    // Calculate the current ISO week number and year for the new start date
    let newFiscalWeek = project.FW;
    const currentWeekNumber = moment(
      project.actual_start_date,
      "YYYY-MM-DD"
    ).isoWeek();
    const newWeekNumber = newStartDate.isoWeek();
    const currentYear = moment(project.actual_start_date, "YYYY-MM-DD").year();
    const newYear = newStartDate.year();

    // Check if the week number or year has changed, indicating a new fiscal week
    if (newWeekNumber !== currentWeekNumber || newYear !== currentYear) {
      newFiscalWeek = calculateNewFiscalWeek(newStartDate);
    }

    // Call API to clone the project with the updated dates and fiscal week

    const response = await addCloneProject(project?.parent_id, {
      assigned_team: project?.assigned_team,
      fiscal_week: newFiscalWeek,
      actual_start_date: newStartDate.format("YYYY-MM-DD"), // Send the incremented date
      actual_start_day: newStartDay, // Send the incremented day
      // cloned_customer_id: project?.id
    });
    if (!response?.status) {
      console.log("response", response);
      toast.error(response?.message || "Something went wrong");
    }

    // Fetch the updated list of projects
    fetchProjects();
  };

  const calculateNewFiscalWeek = (date) => {
    // Find the start of the week (assuming Monday is the start of the week)
    const startOfWeek = date.startOf("isoWeek"); // 'isoWeek' ensures Monday as the start of the week

    const weekNumber = startOfWeek.isoWeek(); // Get ISO week number
    const formattedDate = startOfWeek.format("DD MMM YYYY"); // Format date to "02 Sept 2024"

    // Customize the month abbreviation to ensure correct formatting
    const month = startOfWeek.format("MMMM").slice(0, 4); // Get the full month name and slice to 4 characters
    const formattedMonth = month === "Sept" ? "Sept" : month.slice(0, 3); // If "Sept", use full; else first 3 letters

    const formattedFiscalDate = `${startOfWeek.format(
      "DD"
    )} ${formattedMonth} ${startOfWeek.format("YYYY")}`;

    return `${weekNumber} (${formattedFiscalDate})`;
  };

  const handleDelete = async () => {
    setOpen(true);
    const findParent = projects?.findIndex((p) => p.id !== project?.parent_id);

    if (findParent !== -1) {
      await deleteCloneProject(project?.parent_id, project?.id, {
        actual_start_date: projects[findParent]?.actual_start_date,
      });
    }
    fetchProjects();
    // Call API to delete project

    // Update the state to remove the deleted project
    // setProjects(projects.filter((p) => p.id !== id));
  };

  if (project?.parent_id) {
    console.log("project---", project);
  }

  return (
    <>
      {view === "week" ? (
        <div
          ref={drag}
          onDoubleClick={() => onDoubleClick(id)}
          className={`relative bg-[#ffffff] border border-gray-300 text-black py-2 px-2 mx-1 my-1 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out group ${
            isDragging
              ? "opacity-20 scale-95"
              : "opacity-100 hover:border-blue-500"
          } hover:border-blue-500`}
          style={{ cursor: "move" }}
        >
          <div
            className={`absolute inset-0 z-[-1] transition-transform duration-300 ease-in-out ${
              isDragging ? "rotate-6 shadow-lg" : ""
            }`}
          />

          <div className="absolute top-1 right-2 hidden group-hover:block flex space-x-2">
            <EditIcon
              sx={{ fontSize: "16px" }}
              className="text-gray-500 cursor-pointer"
            />
          </div>
          <div className="text-[14px] mb-2 flex justify-between">
            <span>
              {customerName} {state && "- " + state} - {projectType}
            </span>
          </div>
          <div className="flex space-x-1 pb-0.5">
            <div className=" text-[14px] font-bold text-green-600">
              ${Number(projectSize).toLocaleString()}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div
              className={`flex items-center space-x-1 ${
                formattedDate === "No Date" ? "bg-red-400" : "bg-[#e2b203] "
              } text-gray-800 text-[12px] font-medium px-2 py-1 rounded-sm`}
            >
              <AccessTimeIcon
                sx={{ fontSize: "16px" }}
                className="text-gray-800"
              />
              <span>{formattedDate}</span>
            </div>
            <div className="flex space-x-1">
              <div
                className={`rounded-full w-5 h-5 flex items-center justify-center text-white`}
                style={{ backgroundColor: getColorForButton(delivery_status) }}
              >
                D
              </div>

              <div
                className={`rounded-full w-5 h-5 flex items-center justify-center text-white cursor-pointer `}
                style={{
                  backgroundColor: ops_overall_status ? greenWithOP : redWithOP,
                }}
                onClick={handleOverAllCall}
              >
                O
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={drag}
          onDoubleClick={() => onDoubleClick(id)}
          className={`relative bg-[#ffffff] border border-gray-300 text-black py-1 px-2 mx-1 my-0.5 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out group ${
            isDragging
              ? "opacity-20 scale-95"
              : "opacity-100 hover:border-blue-500"
          } hover:border-blue-500`}
          style={{ cursor: "move" }}
        >
          <div
            className={`absolute inset-0 z-[-1] transition-transform duration-300 ease-in-out ${
              isDragging ? "rotate-6 shadow-lg" : ""
            }`}
          />
          <div className="absolute top-0.5 right-0 flex items-center justify-center space-x-1">
            <FileCopyIcon
              sx={{ fontSize: "16px" }}
              className="text-gray-500 cursor-pointer hover:text-blue-500 transition-colors duration-300"
              onClick={handleClone} // Trigger cloning
            />
            <DeleteIcon
              sx={{ fontSize: "16px" }}
              className="text-red-500 cursor-pointer hover:text-red-700 transition-colors duration-300"
              onClick={handleDelete} // Trigger deletion
            />
          </div>

          <div className="absolute bottom-0 right-1  flex  space-x-2">
            {/* <EditIcon
              sx={{ fontSize: "16px" }}
              className="text-gray-500 cursor-pointer"
            /> */}
          </div>
          <div className="text-[12px] mb-2 flex justify-between">
            <span className="truncate">
              {customerName} {state && "- " + state}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <Tooltip title={project?.ops_task_note}>
              <div className="w-[95px] md:w-[150px] text-[11px] font-bold text-black text-elliplsis-css">
                {project?.ops_task_note || "-"}
              </div>
            </Tooltip>
            <div
              onClick={() =>
                handleEditChange({
                  target: {
                    name: "ops_scheduling_status",
                    value:
                      project?.ops_scheduling_status === "Tentative"
                        ? "Booked"
                        : "Tentative",
                  },
                  ids: { id: project?.id, parent_id: project?.parent_id },
                  type: "DraggableCard",
                })
              }
              className={`flex items-center justify-center cursor-pointer space-x-0.5 ${
                project?.ops_scheduling_status === "Tentative"
                  ? "bg-red-400"
                  : "bg-green-500 "
              } text-gray-800 text-[10px] w-4 h-4  font-medium px-0.5 py-1 rounded-full`}
            >
              {project?.ops_scheduling_status === "Tentative" ? "T" : "B"}
            </div>
            {/* <div
              className={`flex items-center space-x-0.5 ${
                formattedDate === "No Date" ? "bg-red-400" : "bg-[#e2b203] "
              } text-gray-800 text-[10px] font-medium px-0.5 py-1 rounded-sm`}
            >
              {formattedDate !== "No Date" && (
                <AccessTimeIcon
                  sx={{ fontSize: "11px" }}
                  className="text-gray-800"
                />
              )}
              <span>{formattedDate}</span>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default DraggableCard;
