import styled from "@emotion/styled";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
// import GetIcon from "../../assets/get-icon";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  height: 48,
  position: "relative",
  "& .MuiInputBase-root": {
    padding: "10px 12px 10px 12px",
    backgroundColor: "white"
  }
}));

const StyledSelect = styled(Select)(({}) => ({
  fontSize: "13px"
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "13px",
  marginLeft: "2px",
  color: "#202020",
  fontWeight: 400,
  textAlign: "start",
  position: "absolute",
  pointerEvents: "none",
  transformOrigin: "top left",
  transition: "transform 0.2s ease-out",
  transform: "translate(10px, 14px) scale(1)",
  backgroundColor: "white",
  borderRadius: "15px",
  paddingLeft: "5px",

  "&.MuiInputLabel-shrink": {
    transform: "translate(8px, -6px) scale(0.75)"
  }
}));

const StyledMenuItem = styled(MenuItem)(
  ({ theme, selectedBackgroundColor }) => ({
    fontSize: "13px"
    // backgroundColor: selectedBackgroundColor || "white"
  })
);

// const ArrowDropDownIcon = () => {
//   return <GetIcon iconName="dropdown-icon" />;
// };

const DropdownField = ({
  question,
  value,
  options,
  handleChangeDropdown,
  selectedBackgroundColor,
  disabled = false
}) => {
  return (
    <StyledFormControl
      variant="outlined"
      sx={{ width: "100%", height: 48 }}
      disabled={disabled}
    >
      <StyledInputLabel>{question}</StyledInputLabel>
      <StyledSelect
        value={value || ""}
        onChange={handleChangeDropdown}
        style={{ borderColor: "red !important" }}
        // defaultValue={value || ''}
        label={question}
        sx={{
          padding: "10px 12px 10px 20px",
          borderRadius: "10px",
          "& .MuiInputBase-input": {
            padding: "4px 0",
            textAlign: "start",
            paddingRight: "3px !important"
          }
        }}
        // IconComponent={ArrowDropDownIcon}
      >
        {options?.map((option, idx) => (
          <StyledMenuItem key={option + idx} value={option}>
            {option}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default DropdownField;
