const generateRandomId = () => Math.random().toString(36).substr(2, 9);

export const customerProject = [
  {
    id: generateRandomId(),
    name: "John",
    project_type: "Refinishing",
    actual_start_date: "6 june",
    FW: "31 (30 Jul 2024)",
    project_size: "5000",
    paid_amount: "1000",
    due_amount: "4000",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Jane",
    project_type: "Installation",
    actual_start_date: "6 june",
    FW: "31 (31 Jul 2024)",
    project_size: "6000",
    paid_amount: "1500",
    due_amount: "4500",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Mark",
    project_type: "Repair",
    actual_start_date: "6 june",
    FW: "32 (31 Jul 2024)",
    project_size: "1500",
    paid_amount: "500",
    due_amount: "1000",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Sara",
    project_type: "Installation",
    actual_start_date: "6 june",
    FW: "32 (31 Jul 2024)",
    project_size: "2000",
    paid_amount: "500",
    due_amount: "1500",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Paul",
    project_type: "Refinishing",
    actual_start_date: "",
    FW: "33 (7 Aug 2024)",
    project_size: "2500",
    paid_amount: "1000",
    due_amount: "1500",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Anna",
    project_type: "Repair",
    actual_start_date: "",
    FW: "33 (7 Aug 2024)",
    project_size: "4000",
    paid_amount: "1500",
    due_amount: "2500",
    Status: "In Process",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Tom",
    project_type: "Installation",
    actual_start_date: "10 july",
    FW: "",
    project_size: "3500",
    paid_amount: "2000",
    due_amount: "1500",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Emma",
    project_type: "Refinishing",
    actual_start_date: "",
    FW: "34 (14 Aug 2024)",
    project_size: "4500",
    paid_amount: "2500",
    due_amount: "2000",
    Status: "Done",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Sam",
    project_type: "Repair",
    actual_start_date: "10 july",
    FW: "35 (21 Aug 2024)",
    project_size: "2300",
    paid_amount: "1300",
    due_amount: "1000",
    Status: "Pending",
    actual_start_date: ""
  },
  {
    id: generateRandomId(),
    name: "Lily",
    project_type: "Installation",
    actual_start_date: "10 july",
    FW: "",
    project_size: "1600",
    paid_amount: "600",
    due_amount: "1000",
    Status: "Done",
    actual_start_date: ""
  }
];

export const customerLiveDate = [
  {
    id: "2ca3ac89-490f-49fe-b315-3388433cbc9a",
    zc_po_id: "1850122000174450156",
    name: "Jordan Lateiner",
    state: "NJ",
    project_status: "Not Yet Started",
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-04-01",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 2797.57,
    paid_amount: 500,
    due_amount: 2297.57,
    createdAt: "2024-08-08T23:59:39.000Z",
    updatedAt: "2024-08-09T00:08:12.000Z",
    team: "John"
  },
  {
    id: "50065d76-c44b-46c2-aa58-1ab9971babea",
    zc_po_id: "1850122000195235184",
    name: "Patrick Baker",
    state: "NJ",
    project_status: "Not Yet Started",
    project_type: "Hardwood Installation",
    actual_start_date: "2024-08-19",
    fiscal_week: "34 (19 Aug 2024)",
    actual_start_date: "2024-08-18",
    project_size: 0,
    paid_amount: 500,
    due_amount: -500,
    createdAt: "2024-08-08T02:20:33.000Z",
    updatedAt: "2024-08-08T14:47:55.000Z",
    team: "petter"
  },
  {
    id: "d32eaf8e-3b4e-4606-929f-adfb88acafe0",
    zc_po_id: "1850122000196076340",
    name: "Madhvi Gattani",
    state: "NJ",
    project_status: "Not Yet Started",
    project_type: null,
    actual_start_date: "2024-08-19",
    fiscal_week: "34 (19 Aug 2024)",
    actual_start_date: "2024-08-18",
    project_size: 12635.3,
    paid_amount: 0,
    due_amount: 12635.3,
    createdAt: "2024-08-08T01:47:32.000Z",
    updatedAt: "2024-08-08T14:13:59.000Z",
    team: "tom"
  },
  {
    id: "175bc488-055d-4284-b200-ee27de1b93a8",
    zc_po_id: "18501220001855484990230660",
    name: 'Ursula Clark "Project 2"',
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "LVT",
    actual_start_date: "2024-08-02",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 2270.15,
    createdAt: "2024-08-06T05:22:21.000Z",
    updatedAt: "2024-08-06T05:22:21.000Z"
  },
  {
    id: "175bc488-055d-4284-b200-ee27de1b93a8",
    zc_po_id: "18501220001855484990230660",
    name: 'Ursula Clark "Project 2"',
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "LVT",
    actual_start_date: "2024-08-02",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 2270.15,
    createdAt: "2024-08-06T05:22:21.000Z",
    updatedAt: "2024-08-06T05:22:21.000Z"
  },
  {
    id: "175bc488-055d-4284-b200-ee27de1b93a8",
    zc_po_id: "18501220001855484990230660",
    name: 'Ursula Clark "Project 2"',
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "LVT",
    actual_start_date: "2024-08-02",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 2270.15,
    createdAt: "2024-08-06T05:22:21.000Z",
    updatedAt: "2024-08-06T05:22:21.000Z"
  },
  {
    id: "68381ab7-5fc2-467e-b201-372e760b4fb6",
    zc_po_id: "185012200018137613930023",
    name: 'Matthew Moye "2"',
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: null,
    actual_start_date: "2024-07-01",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 7888.8,
    due_amount: 0,
    createdAt: "2024-08-06T05:24:42.000Z",
    updatedAt: "2024-08-06T05:24:42.000Z"
  },
  {
    id: "482172f3-4ab2-4326-aa63-ff88aee1fa4e",
    zc_po_id: "185012200001651718789797454",
    name: "Connie Larezzo",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-09-02",
    fiscal_week: "36 (02 Sept 2024)",
    actual_start_date: "2024-09-02",
    project_size: 0,
    paid_amount: 0,
    due_amount: 1872.76,
    createdAt: "2024-08-06T05:25:13.000Z",
    updatedAt: "2024-08-09T10:55:11.000Z"
  },
  {
    id: "a5e24879-8115-4552-8386-d08cd69a2eb6",
    zc_po_id: "185012200001651718789797454151",
    name: "Nona Phillips",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Laminate Installation",
    actual_start_date: "2024-07-31",
    fiscal_week: "33 (12 Aug 2024)",
    actual_start_date: "2024-08-12",
    project_size: 0,
    paid_amount: 500,
    due_amount: 12000.2,
    createdAt: "2024-08-06T05:25:36.000Z",
    updatedAt: "2024-08-08T11:20:19.000Z"
  },
  {
    id: "a5e24879-8115-4552-8386-d08cd69a2eb6",
    zc_po_id: "185012200001651718789797454151",
    name: "Nona Phillips",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Laminate Installation",
    actual_start_date: "2024-07-31",
    fiscal_week: "33 (12 Aug 2024)",
    actual_start_date: "2024-08-12",
    project_size: 0,
    paid_amount: 500,
    due_amount: 12000.2,
    createdAt: "2024-08-06T05:25:36.000Z",
    updatedAt: "2024-08-08T11:20:19.000Z"
  },
  {
    id: "5e9671b6-faf8-464c-a3c0-ff3cf9fcf81c",
    zc_po_id: "185012200019554621000065",
    name: "Kisha Phillips",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: null,
    actual_start_date: "2024-08-12",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 2229.78,
    createdAt: "2024-08-06T05:25:54.000Z",
    updatedAt: "2024-08-06T05:25:54.000Z"
  },
  {
    id: "416ec6a4-88a9-4b4e-abd2-6199047f4875",
    zc_po_id: "185012200018204704802052",
    name: "Janis Arnold",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Hardwood Installation",
    actual_start_date: "2024-08-08",
    fiscal_week: "39 (23 Sept 2024)",
    actual_start_date: "2024-09-23",
    project_size: 0,
    paid_amount: 0,
    due_amount: 3134.78,
    createdAt: "2024-08-06T05:26:37.000Z",
    updatedAt: "2024-08-09T10:50:31.000Z"
  },
  {
    id: "416ec6a4-88a9-4b4e-abd2-6199047f4875",
    zc_po_id: "185012200018204704802052",
    name: "Janis Arnold",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Hardwood Installation",
    actual_start_date: "2024-08-08",
    fiscal_week: "39 (23 Sept 2024)",
    actual_start_date: "2024-09-23",
    project_size: 0,
    paid_amount: 0,
    due_amount: 3134.78,
    createdAt: "2024-08-06T05:26:37.000Z",
    updatedAt: "2024-08-09T10:50:31.000Z"
  },
  {
    id: "215b04db-0465-4d17-a2be-8790df1eac1f",
    zc_po_id: "18501220000165171878979797",
    name: "Dave Anderson",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-07-10",
    fiscal_week: "32 (05 Aug 2024)",
    actual_start_date: "2024-08-05",
    project_size: 0,
    paid_amount: 7082.31,
    due_amount: 0,
    createdAt: "2024-08-06T05:26:57.000Z",
    updatedAt: "2024-08-09T09:51:49.000Z"
  },
  {
    id: "8ba6377b-7c3d-4632-b8b2-c6f612e01eab",
    zc_po_id: "18501220001942090744115",
    name: "Stephen Ippolito",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-08-05",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 5904.63,
    createdAt: "2024-08-06T05:27:16.000Z",
    updatedAt: "2024-08-06T05:27:16.000Z"
  },
  {
    id: "8ba6377b-7c3d-4632-b8b2-c6f612e01eab",
    zc_po_id: "18501220001942090744115",
    name: "Stephen Ippolito",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-08-05",
    fiscal_week: null,
    actual_start_date: null,
    project_size: 0,
    paid_amount: 0,
    due_amount: 5904.63,
    createdAt: "2024-08-06T05:27:16.000Z",
    updatedAt: "2024-08-06T05:27:16.000Z"
  },
  {
    id: "a746621d-2efb-4396-9937-bc70aa3bfeb4",
    zc_po_id: "185012200001651718789797454151595956262478",
    name: "Sirius Stamm",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Laminate Installation",
    actual_start_date: "2024-08-22",
    fiscal_week: "33 (12 Aug 2024)",
    actual_start_date: "2024-08-12",
    project_size: 0,
    paid_amount: 0,
    due_amount: 12165.3,
    createdAt: "2024-08-06T05:27:57.000Z",
    updatedAt: "2024-08-09T08:30:24.000Z"
  },
  {
    id: "c139d683-f13c-4a67-b639-d04a43dfd40f",
    zc_po_id: "18501220001903883090205050",
    name: "Argenis De La Cruz",
    state: null,
    project_status: "Not Yet Started",
    assigned_team: null,
    project_type: "Sanding and Refinishing",
    actual_start_date: "2024-06-26",
    fiscal_week: "38 (16 Sept 2024)",
    actual_start_date: "2024-09-16",
    project_size: 0,
    paid_amount: 6081.6,
    due_amount: 0,
    createdAt: "2024-08-06T05:28:18.000Z",
    updatedAt: "2024-08-09T10:50:29.000Z"
  }
];
