import React, { useState } from "react";
import { Popover, TextField, Button } from "@mui/material";
import styled from "@emotion/styled";

const NoteTextField = styled(TextField)(({}) => ({
  "&.MuiTextField-root": {
    "& .MuiInputBase-input": {
      padding: "14px",
      fontSize: "13px",
      textAlign: "center"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px"
    },
    "& .css-lccy1c-MuiGrid-root": {
      paddingRight: "0"
    },
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "3px",
      paddingTopL: "3px",
      backgroundColor: "white",
      borderRadius: "10px",
      textAlign: "center"
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "0"
    }
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& fieldset>legend": {
      fontSize: "10px"
    }
  }
}));

const ProjectSizePopover = ({
  anchorEl,
  open,
  handleClose,
  projectSizeRange,
  handleMinSizeChange,
  handleMaxSizeChange
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <div className="p-3 w-[250px] flex space-x-1">
        <NoteTextField
          label="Min Size"
          value={Math.round(projectSizeRange[0]) || 0}
          onChange={handleMinSizeChange}
          type="number"
          variant="outlined"
          style={{ marginBottom: "8px" }}
        />
        <NoteTextField
          label="Max Size"
          value={Math.round(projectSizeRange[1]) || 0}
          onChange={handleMaxSizeChange}
          type="number"
          variant="outlined"
        />
      </div>
    </Popover>
  );
};

export default ProjectSizePopover;
