import React from "react";
import DayColumn from "../DayColumn";
import SlickSlider from "../SlickSlider";

const DayColumnsLayout = ({
  selectedWeek,
  startDate,
  startWeek,
  weekDayProject,
  moveCard,
  handleDoubleClick,
  extractWeekNumber,
  getFiscalWeekStartDate,
  fetchProjects
}) => {
  // Calculate the start date of the selected week
  const weekStartDate = getFiscalWeekStartDate(
    startDate,
    extractWeekNumber(selectedWeek) - startWeek
  );

  // Get the current year from the start date
  const currentYear = weekStartDate.getFullYear();

  // Generate the days of the week dynamically, excluding Sunday
  const daysOfWeek = [];
  let currentDay = new Date(weekStartDate);

  // Move back to the previous Monday if the start date isn't Monday
  while (currentDay.getDay() !== 1) {
    currentDay.setDate(currentDay.getDate() - 1);
  }

  while (currentDay.getFullYear() === currentYear && daysOfWeek.length < 6) {
    // Exclude Sunday
    if (currentDay.getDay() !== 0) {
      daysOfWeek.push(new Date(currentDay));
    }
    currentDay.setDate(currentDay.getDate() + 1);
  }
  const settings = {
    dots: true, // Set to true if you want to show navigation dots
    infinite: false, // Set to true if you want the slider to loop back to the beginning
    speed: 500,
    slidesToShow: 5, // Adjust this number to show more or fewer columns at a time
    slidesToScroll: 5, // Adjust this number to scroll one column at a time
    draggable: true
  };

  return (
    <div className="grid grid-cols-6 gap-4">
      {/* <SlickSlider {...settings}> */}
      {daysOfWeek.map((dayDate, index) => {
        // Format the date to display
        const formattedDate = dayDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric" // Include the year in the format
        });

        const dayName = dayDate.toLocaleDateString("en-GB", {
          weekday: "short" // Get the day name (Mon, Tue, etc.)
        });

        return (
          <DayColumn
            key={dayName}
            day={`${dayName}`} // Pass the day name
            selectedWeek={selectedWeek}
            projects={weekDayProject?.filter(
              (project) =>
                project?.actual_start_day === dayName && !project?.assigned_team
            )}
            handleDrop={moveCard} // Pass the function here
            onDoubleClick={handleDoubleClick}
            formattedDate={formattedDate}
            fetchProjects={fetchProjects}
          />
        );
      })}
      {/* </SlickSlider> */}
    </div>
  );
};

export default DayColumnsLayout;
