import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationPopup = ({
  open,
  handleEditClose,
  onConfirm, // Function to handle confirm action
  message // Default confirmation message
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleEditClose}
      PaperProps={{
        style: { backgroundColor: "#ffffff", borderRadius: "16px" }
      }}
      maxWidth="sm" // Adjusted for confirmation dialog size
      fullWidth
    >
      <Box
        sx={{
          padding: "16px",
          backgroundColor: "#f1f2f4",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          borderBottom: "1px solid #ccc",
          position: "relative"
        }}
      >
        <Typography variant="h6">Confirmation</Typography>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign: "center", padding: "24px" }}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
        <Button
          onClick={handleEditClose}
          variant="outlined"
          color="secondary"
          sx={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
