import React from "react";
import { useDrop } from "react-dnd";
import { Box, Typography, Paper } from "@mui/material";
import DraggableCard from "./DraggableCard";
import moment from "moment";

const DayColumn = ({
  day,
  date,
  projects = [],
  handleDrop,
  formattedDate,
  onDoubleClick,
  team,
  teamName,
  setProjects,
  allProjects,
  fetchProjects,
  setOpen,
  handleEditChange
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ["PARENT_CARD", "CHILD_CARD"],
    drop: async (item) => {
      if (item.type === "PARENT_CARD") {
        setOpen(true);
        await handleDrop(
          item,
          day,
          moment(formattedDate).format("YYYY-MM-DD"),
          teamName,
          item.type
        );
        setOpen(false);
      } else if (item.type === "CHILD_CARD") {
        setOpen(true);

        await handleDrop(
          item,
          day,
          moment(formattedDate).format("YYYY-MM-DD"),
          teamName,
          item.type
        );
        setOpen(false);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <>
      {team ? (
        <>
          {" "}
          <Box
            ref={drop}
            sx={{
              flex: 1,
              minWidth: "150px",
              maxHeight: "50vh",
              margin: "2px 4px 2px 6px",
              borderRadius: "8px"
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 0.5,
                // maxHeight: "80vh",
                minHeight: "200px",
                overflowY: "auto",
                height: "100%"
              }}
            >
              {projects.map((project) => (
                <DraggableCard
                  key={project.id}
                  id={project.id}
                  customerName={project["name"]}
                  projectType={project["project_type"]}
                  projectSize={project["project_size"]}
                  estimatedStartDate={project["estimated_start_date"]}
                  state={project["state"]}
                  onDoubleClick={onDoubleClick}
                  view={"day"}
                  setProjects={setProjects}
                  project={project}
                  projects={allProjects}
                  dragType={"CHILD_CARD"}
                  fetchProjects={fetchProjects}
                  setOpen={setOpen}
                  handleEditChange={handleEditChange}
                />
              ))}
            </Paper>
          </Box>
        </>
      ) : (
        <Box
          ref={drop}
          sx={{
            flex: 1,
            minWidth: "150px",
            // minHeight: "100%",
            margin: "0px 4px 0px 4px",
            borderRadius: "8px"
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: isOver ? "#cccccc" : "#f1f2f4",
              zIndex: 1,
              padding: "6px",
              borderTopRightRadius: "8px",
              borderTopLeftRadius: "8px"
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>
              {day} - {formattedDate}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DayColumn;
