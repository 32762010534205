import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../components/HomePage";
import WithSecurity from "./WithSecurity";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/home" />} />

        <Route
          exact
          path="/home"
          element={
            <WithSecurity>
              <HomePage />
            </WithSecurity>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
