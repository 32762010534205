import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let customDotStyles = `
    .slick-dots {
      bottom: 0px;
    }

    .slick-dots li button:before {
      color: #ffffff;
      font-size: 20px !important;
    }

    // .slick-dots li button {
    //   width: 100px;
    //   height: 100px;
    // }
    .slick-slider{
        height:100%
    }
    .slick-list{
        height:100%
    }
  
    
  `;

const SlickSlider = ({
  children,
  infinite = false,
  slidesToShow = 1,
  slidesToScroll = 1,
  handleAfterChange,
  initialSlide = 0
}) => {
  const sliderSettings = {
    dots: false,
    infinite: infinite,
    speed: 500,
    // slidesToShow: slidesToShow,
    // slidesToScroll: slidesToScroll,

    // dotsClass: "slick-dots",
    afterChange: handleAfterChange,
    initialSlide,

    slidesToShow: 5,
    swipeToSlide: true,
    arrows: false

    // appendDots: (dots) => (
    //   <div>
    //     {dots.map((dot, index) =>
    //       index == 0 ? (
    //         <div className="rounded-full bg-black w-4 h-4 left-[560px] gap-4" />
    //       ) : (
    //         <div className="rounded-full bg-gray-500 w-4 h-4 left-[560px] gap-4" />
    //       )
    //     )}
    //   </div>
    // ),
  };

  return (
    <>
      <style>{customDotStyles}</style>
      <Slider style={{ height: "100%" }} {...sliderSettings}>
        {children}
      </Slider>
    </>
  );
};

export default SlickSlider;
