import React, { useState, useEffect, useRef } from "react";
import FilterControls from "./FilterControls";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Column from "./Column";
import DayColumn from "./DayColumn"; // New Component for Day View
import EditCardDialog from "./EditCard/EditCardDialog";
import {
  addCloneProject,
  bulkDeleteCloneProject,
  deleteCloneProject,
  getCustomers,
  updateCustomer,
  updateOpsScheduling,
} from "./services/finance.service";
import dayjs from "dayjs";
import { projectTypeOption } from "../utils/Forms";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkeletonLoader from "./SkeletonLoader";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSlider from "./SlickSlider";

// Import Material-UI Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { customerLiveDate } from "./data";
import WeeklyProjectColumn from "./WeeklyProjectColumn";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import MainComponent from "./Team/TeamMainComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ConfirmationPopup from "./UI/ConfirmationPopup";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const HomePage = () => {
  const [open, setOpen] = React.useState(false);
  const [parentShiftOpen, setParentShiftOpen] = useState(false);

  const [projects, setProjects] = useState([]);
  const [weekDayProject, setWeekDayProject] = useState([]);
  const [startWeek, setStartWeek] = useState(getCurrentFiscalWeekNumber());
  const [startDate, setStartDate] = useState(getCurrentFiscalWeekStartDate());
  const [currentFW, setCurrentFW] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filter, setFilter] = useState({
    projectType: "",
    projectSizeRange: null,
    startDateRange: [null, null],
    searchQuery: "",
  });
  const [singleParentData, setSingleParentData] = useState(null);

  console.log(startDate, "startDate");

  const [values, setValues] = useState([null, null]); // Initialize values state
  const [minProjectSize, setMinProjectSize] = useState(0); // Initialize minProjectSize state
  const [maxProjectSize, setMaxProjectSize] = useState(100000); // Initialize maxProjectSize state
  const [isLoading, setIsLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const [view, setView] = useState("week");
  const [selectedWeek, setSelectedWeek] = useState(null); // Track the selected week
  const [backlogColumnProjects, setBacklogColumnProjects] = useState([]);
  const [weekDayBacklogProject, setWeekDayBacklogProject] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  console.log("weekDayProject--", weekDayProject);

  // After fetching all projects
  useEffect(() => {
    if (view === "week") {
      setBacklogColumnProjects(filteredProjects); // Set initially to all filtered projects
    } else if (view === "day") {
      const updatedBacklogProjects = filteredProjects?.filter(
        (project) =>
          project?.FW === selectedWeek &&
          !project?.parent_id &&
          project?.clones?.length == 0
      );

      console.log(
        "updatedBacklogProjects--1",
        updatedBacklogProjects,
        filteredProjects
      );
      setWeekDayProject(
        filteredProjects?.filter((project) => project?.FW === selectedWeek)
      );
      setWeekDayBacklogProject(updatedBacklogProjects);
    }
  }, [filteredProjects, view, selectedWeek, projects, parentShiftOpen]);

  console.log("projects--", projects, weekDayBacklogProject);

  const getYearStartDate = (year) => new Date(year, 0, 1); // January 1st
  const getYearEndDate = (year) => new Date(year, 11, 31); // December 31st

  const fetchProjects = async (startYear = new Date().getFullYear()) => {
    // setIsLoading(true);

    // Define start and end dates based on the provided year
    const startDate = getYearStartDate(startYear);
    const endDate = getYearEndDate(startYear);

    const startDateStr = moment(startDate).format("YYYY-MM-DD");
    const endDateStr = moment(endDate).format("YYYY-MM-DD");

    try {
      const customers = await getCustomers(startDateStr, endDateStr); // Adjust API call if necessary

      const updatedProjects = customers?.data?.entity?.map((customer) => ({
        ...customer,
        id: customer?.id,
        name: customer?.name,
        project_type: customer?.project_type,
        estimated_start_date: customer?.estimated_start_date
          ? dayjs(customer.estimated_start_date).format("DD/MM/YYYY")
          : null,
        FW: customer?.fiscal_week,
        project_size: calculateProjectSize(
          customer?.paid_amount,
          customer?.due_amount
        ),
        paid_amount: customer?.paid_amount,
        due_amount: customer?.due_amount,
        Status: customer?.project_status,
        actual_start_date: customer?.actual_start_date,
        state: customer?.state,
        actual_start_day: customer?.actual_start_day || null,
        assigned_team: customer?.assigned_team || null,
        delivery_status: customer?.delivery_status || null,
        zc_po_id: customer?.zc_po_id,
        ops_overall_status: customer?.ops_overall_status,
        parent_id: customer?.parent_id || null,
      }));

      setProjects(updatedProjects);

      console.log("updatedProjects-", updatedProjects);

      const today = new Date();
      const currentWeekNumber = getFiscalWeekNumber(startDate, today);
      const currentWeekStartDate = getFiscalWeekStartDate(
        startDate,
        currentWeekNumber - 1
      );
      setCurrentFW(
        formatFiscalWeekTitle(currentWeekNumber, currentWeekStartDate)
      );
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    applyFilter();
  }, [filter, projects, values, view, selectedWeek, parentShiftOpen]);

  const applyFilter = () => {
    let filtered =
      view == "week"
        ? projects
        : projects?.filter((project) => project?.FW === selectedWeek);

    if (filter?.projectType && filter?.projectType !== "All") {
      filtered = filtered?.filter(
        (project) => project["project_type"] === filter.projectType
      );
    }

    if (filter?.projectSizeRange && filter?.projectSizeRange.length === 2) {
      const [projectSizeFrom, projectSizeTo] = filter?.projectSizeRange;
      filtered = filtered?.filter(
        (project) =>
          Number(project["project_size"]) >= projectSizeFrom &&
          Number(project["project_size"]) <= projectSizeTo
      );
    }

    if (values?.[0] && values?.[1]) {
      filtered = filtered?.filter(
        (project) =>
          dayjs(project["estimated_start_date"], "DD/MM/YYYY").isSameOrAfter(
            dayjs(values?.[0])
          ) &&
          dayjs(project["estimated_start_date"], "DD/MM/YYYY").isSameOrBefore(
            dayjs(values?.[1])
          )
      );
    }
    // // Add search query filter
    // if (filter?.searchQuery) {
    //   const searchQueryLower = filter.searchQuery.toLowerCase();
    //   filtered = filtered.filter((project) =>
    //     project.name.toLowerCase().includes(searchQueryLower)
    //   );
    // }

    setFilteredProjects(filtered);
  };

  const handleFilterChange = (name, value) => {
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const handleMinSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    handleFilterChange("projectSizeRange", [
      value,
      filter?.projectSizeRange ? filter?.projectSizeRange[1] : 0,
    ]);
  };

  const handleMaxSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    handleFilterChange("projectSizeRange", [
      filter?.projectSizeRange ? filter?.projectSizeRange[0] : 0,
      value,
    ]);
  };

  const handleClearFilters = () => {
    setFilter({
      projectType: "",
      projectSizeRange: [minProjectSize, maxProjectSize],
      startDateRange: [null, null],
      searchQuery: "",
    });
    setValues([null, null]);
  };

  const moveCard = async (cardId, newTargetFW, date, teamName, dragType) => {
    if (view === "week") {
      const weekNumber = extractWeekNumber(newTargetFW);
      const year = extractYear(newTargetFW);
      const startDate = new Date(`${year}-01-01`);
      const fiscalWeekStartDate = getFiscalWeekStartDate(
        startDate,
        weekNumber - 1
      );

      let updatedProject = null;

      const originalProjects = [...projects];

      setProjects((prevProjects) => {
        const updatedProjects = prevProjects.map((project) => {
          if (project.id === cardId) {
            updatedProject = {
              ...project,
              FW: newTargetFW === "unassigned" ? null : newTargetFW,
              actual_start_date:
                newTargetFW === "unassigned"
                  ? null
                  : moment(fiscalWeekStartDate).format("YYYY-MM-DD"),
            };
            return updatedProject;
          }
          return project;
        });
        return updatedProjects;
      });

      if (updatedProject) {
        const response = await updateCustomer(cardId, {
          fiscal_week: updatedProject?.FW,
          actual_start_date: updatedProject?.actual_start_date,
          estimated_start_date: updatedProject?.["estimated_start_date"]
            ? moment(
                updatedProject["estimated_start_date"],
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")
            : null,
        });
        if (response?.data?.status) {
          console.log("Customer updated successfully:", response);
        } else {
          console.error("Error updating customer:", response?.message);
          setProjects(originalProjects);
          toast.error(response?.message || "Something went wrong");
        }
      }
    } else if (view === "day") {
      const originalProjects = [...projects];

      // Find the project details using the item id
      const projectToUpdate = originalProjects.find(
        (project) => project.id === cardId.id
      );

      if (!projectToUpdate) {
        console.error("Project not found for ID:", cardId.id);
        return;
      }
      let dateObject;
      if (newTargetFW === "unassigned") {
        const weekString = date;

        // Split the string at the opening parenthesis and take the second part
        const datePart = weekString?.split("(")[1].slice(0, -1); // "02 Sept 2024"

        // Parse the date string into a Date object
        dateObject = new Date(datePart);
      }

      const updatedProject = {
        ...projectToUpdate, // Use the full project details
        actual_start_day: newTargetFW === "unassigned" ? null : newTargetFW, // Set the actual start date to the selected day,
        actual_start_date:
          newTargetFW === "unassigned"
            ? moment(dateObject).format("YYYY-MM-DD") || null
            : date,
        assigned_team: teamName || null,
      };

      console.log("item", updatedProject, newTargetFW);

      // Update the projects state
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === cardId.id ? updatedProject : project
        )
      );
      if (dragType == "PARENT_CARD") {
        try {
          const response = await addCloneProject(cardId.id, {
            actual_start_date: updatedProject.actual_start_date,
            actual_start_day: updatedProject.actual_start_day,
            assigned_team: teamName || null,
            fiscal_week: selectedWeek,
          });

          if (response?.data?.status) {
            fetchProjects();
            console.log("Project updated successfully:", response);
          } else {
            fetchProjects();
            console.error("Error updating project:", response?.message);
            setProjects(originalProjects); // Revert to original state if API call fails
            toast.error(response?.message || "Something went wrong");
          }
        } catch (error) {
          console.error("Error updating project:", error);
          setProjects(originalProjects); // Revert to original state if API call fails
          toast.error(error?.message || "Something went wrong");
        }
      } else if (dragType === "CHILD_CARD") {
        // Uncomment the API call to update the project on the server
        try {
          const response = await addCloneProject(cardId.parent_id, {
            actual_start_date: updatedProject.actual_start_date,
            actual_start_day: updatedProject.actual_start_day,
            assigned_team: teamName || null,
            fiscal_week: selectedWeek,
            cloned_customer_id: updatedProject?.id,
          });

          if (response?.data?.status) {
            console.log("Project updated successfully:", response);
          } else {
            console.error("Error updating project:", response?.data?.message);
            setProjects(originalProjects); // Revert to original state if API call fails
            toast.error(response?.message || "Something went wrong");
          }
        } catch (error) {
          console.error("Error updating project:", error);
          setProjects(originalProjects); // Revert to original state if API call fails
          toast.error(error?.message || "Something went wrong");
        }
      }
    }
  };

  const handleDoubleClick = (cardId) => {
    const card = projects.find((project) => project.id === cardId);
    setEditCard(card);
    setEditOpen(true);
  };

  const handleEditChange = async (e) => {
    const { name, value } = e.target;
    const { id, parent_id } = e.ids;
    const originalProjects = [...projects];

    let updatedCard = null;
    if (e.type === "EditCard") {
      setEditCard((prevCard) => {
        updatedCard = { ...prevCard, [name]: value };
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.id === updatedCard.id ? updatedCard : project
          )
        );
        return updatedCard;
      });
    } else {
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === id ? { ...project, [name]: value } : project
        )
      );
    }

    if (name === "ops_task_note" || name === "ops_scheduling_status") {
      try {
        const response = await updateOpsScheduling(parent_id, id, {
          [name]: value,
        });
        console.log("Customer updated successfully:", response);
        if (!response?.status) {
          toast.error(response?.message || "Something went wrong");
          setProjects(originalProjects);
        }
      } catch (error) {
        console.error("Error updating customer:", error);
        setProjects(originalProjects);
        toast.error(error?.message || "Something went wrong");
      }
    } else {
      try {
        const response = await updateCustomer(updatedCard.id, {
          project_status: name === "Status" ? value : updatedCard?.Status,
          assigned_team:
            name === "assigned_team" ? value : updatedCard?.assigned_team,
          estimated_start_date: updatedCard?.["estimated_start_date"]
            ? moment(updatedCard["estimated_start_date"], "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          fiscal_week: updatedCard?.FW ? updatedCard?.FW : null,
        });
        console.log("Customer updated successfully:", response);
        if (!response?.status) {
          toast.error(response?.message || "Something went wrong");

          setProjects(originalProjects);
        }
      } catch (error) {
        console.error("Error updating customer:", error);
        setProjects(originalProjects);
        toast.error(error?.message || "Something went wrong");
      }
    }
  };

  const handleDateChange = async (date) => {
    const originalProjects = [...projects];

    let updatedProject = null;

    setEditCard((prevCard) => {
      const formattedDate = moment(date).format("DD/MM/YYYY");
      updatedProject = {
        ...prevCard,
        estimated_start_date: formattedDate,
      };
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === updatedProject.id ? updatedProject : project
        )
      );
      return updatedProject;
    });

    try {
      const response = await updateCustomer(editCard.id, {
        estimated_start_date: date,
        fiscal_week: editCard?.FW ? editCard?.FW : null,
      });
      console.log("Customer updated successfully:", response);
      if (!response?.status) {
        toast.error(response?.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error updating customer:", error);
      setProjects(originalProjects);
      toast.error(error?.message || "Something went wrong");
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleWeekTitleClick = (weekTitle) => {
    setSelectedWeek(weekTitle);

    setView("day");
  };

  const handleBackToWeekView = () => {
    setSelectedWeek(null);
    setWeekDayProject([]);
    setWeekDayBacklogProject([]);
    setView("week"); // Switch back to week view
  };

  useEffect(() => {
    fetchProjects(year);
  }, [year]);

  const handleNext = () => {
    if (view === "week") {
      setYear(year + 1);
      // setStartWeek((prevStart) => prevStart + 5);
      // setStartDate((prevStartDate) => getFiscalWeekStartDate(prevStartDate, 5));
    } else if (view === "day") {
      // Calculate the next week's start date based on the selectedWeek
      const currentWeekNumber = extractWeekNumber(selectedWeek);
      const currentWeekStartDate = getFiscalWeekStartDate(
        startDate,
        currentWeekNumber - startWeek
      );
      const nextWeekStartDate = getFiscalWeekStartDate(currentWeekStartDate, 1);

      // Set the selected week to the next week
      const nextWeekTitle = formatFiscalWeekTitle(
        Number(currentWeekNumber) + 1,
        nextWeekStartDate
      );

      setSelectedWeek(nextWeekTitle);
    }
  };

  const handlePrevious = () => {
    if (view === "week") {
      // setStartWeek((prevStart) => {
      //   const newStartWeek = prevStart - 5;
      //   if (newStartWeek < 1) {
      //     const previousYear = new Date(startDate).getFullYear() - 1;
      //     const startOfYear = new Date(previousYear, 0, 1);
      //     const weeksInPreviousYear = getFiscalWeekNumber(
      //       startOfYear,
      //       new Date(previousYear, 11, 31)
      //     );
      //     const wrappedWeekNumber = weeksInPreviousYear + newStartWeek;
      //     const newStartDate = getFiscalWeekStartDate(
      //       startOfYear,
      //       wrappedWeekNumber - 1
      //     );
      //     setStartDate(newStartDate);
      //     return wrappedWeekNumber;
      //   } else {
      //     setStartDate((prevStartDate) =>
      //       getFiscalWeekStartDate(prevStartDate, -5)
      //     );
      //     return newStartWeek;
      //   }
      // });
      setYear(year - 1);
    } else if (view === "day") {
      // Calculate the previous week's start date based on the selectedWeek
      const currentWeekNumber = extractWeekNumber(selectedWeek);
      const currentWeekStartDate = getFiscalWeekStartDate(
        startDate,
        currentWeekNumber - startWeek
      );
      const previousWeekStartDate = getFiscalWeekStartDate(
        currentWeekStartDate,
        -1
      );

      // Set the selected week to the previous week
      const previousWeekTitle = formatFiscalWeekTitle(
        Number(currentWeekNumber) - 1,
        previousWeekStartDate
      );
      setSelectedWeek(previousWeekTitle);
    }
  };

  const [fiscalWeeks1, setFiscalWeeks1] = useState([]);
  const currentFiscalWeekRef = useRef(null);

  const backlogColumn = {
    id: "unassigned",
    title: "Backlogs",
    projects:
      backlogColumnProjects?.filter((p) => !p.FW || p.FW === "unassigned") ||
      [],
  };
  const dayColumn = {
    id: "unassigned",
    title: "weekly",
    projects: weekDayBacklogProject,
  };

  console.log("filteredProjects-", filteredProjects, fiscalWeeks1);

  const columns = fiscalWeeks1.map((fw) => ({
    id: fw,
    title: fw,
    projects: filteredProjects?.filter(
      (project) =>
        extractWeekNumber(project.FW) === extractWeekNumber(fw) &&
        extractYear(project.FW) === extractYear(fw) &&
        !project?.parent_id
    ),
  }));

  useEffect(() => {
    generateFiscalWeeks(year);
  }, [year]);

  useEffect(() => {
    if (currentFiscalWeekRef.current) {
      currentFiscalWeekRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [fiscalWeeks1]);

  const generateFiscalWeeks = (yearForFw) => {
    const year = yearForFw;
    const startDate = new Date(year, 0, 1); // January 1st, 2024
    const fiscalWeeksArray = [];
    let weekNumber = 1;
    let currentWeek = null;

    while (startDate.getFullYear() === year) {
      const weekStartDate = new Date(startDate);
      fiscalWeeksArray.push(formatFiscalWeekTitle1(weekNumber, weekStartDate));

      if (checkIfCurrentWeek(weekStartDate)) {
        currentWeek = weekNumber;
      }

      // Move to the next week (7 days ahead)
      startDate.setDate(startDate.getDate() + 7);
      weekNumber++;
    }

    setFiscalWeeks1(fiscalWeeksArray);

    // If no current week is found, set the first week as current
    if (!currentWeek) {
      currentWeek = 1;
    }

    setTimeout(() => {
      if (currentFiscalWeekRef.current) {
        currentFiscalWeekRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0); // Delay for scrolling to ensure DOM updates
  };

  const checkIfCurrentWeek = (weekStartDate) => {
    const today = new Date();
    const endOfWeek = new Date(weekStartDate);
    endOfWeek.setDate(weekStartDate.getDate() + 6); // End of the week
    return today >= weekStartDate && today <= endOfWeek;
  };

  const formatFiscalWeekTitle1 = (weekNumber, date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return `${weekNumber} (${formattedDate})`;
  };

  console.log("dayColumn--", columns, dayColumn, filteredProjects);
  const currentFiscalWeekIndex = columns.findIndex(
    (column) => column.title === currentFW // Assuming `currentFW` is the title of the current fiscal week
  );
  console.log(currentFiscalWeekIndex, "formatFiscalWeekTitle");
  const settings = {
    dots: true, // Set to true if you want to show navigation dots
    infinite: false, // Set to true if you want the slider to loop back to the beginning
    speed: 500,
    slidesToShow: 5, // Adjust this number to show more or fewer columns at a time
    slidesToScroll: 5, // Adjust this number to scroll one column at a time
    draggable: true,
    initialSlide: currentFiscalWeekIndex || 0,
  };

  const dayViewSettings = {
    dots: true, // Set to true if you want to show navigation dots
    infinite: false, // Set to true if you want the slider to loop back to the beginning
    speed: 500,
    slidesToShow: 1, // Adjust this number to show more or fewer columns at a time
    slidesToScroll: 1, // Adjust this number to scroll one column at a time
    draggable: true,
    handleAfterChange: (current) => console.log(current),
  };
  const handleConfirmation = (item) => {
    setParentShiftOpen(true);
    setSingleParentData(item);
  };

  const moveConfirmation = async () => {
    // setOpen(true);
    await bulkDeleteCloneProject(singleParentData?.project?.id, {
      cloned_customer_ids:
        singleParentData?.project?.clones?.map((pro) => pro?.id) || [],
    });
    await moveCard(singleParentData.id, singleParentData?.FW);
    await fetchProjects();
    setParentShiftOpen(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="flex">
        <ToastContainer />
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="w-[20vw]">
              <div className="flex-none h-full">
                {view === "day" ? (
                  <WeeklyProjectColumn
                    key={dayColumn?.id}
                    column={dayColumn}
                    moveCard={moveCard}
                    backlog={true}
                    onDoubleClick={handleDoubleClick}
                    view={view}
                    selectedWeek={selectedWeek}
                    setProjects={setProjects}
                    projects={projects}
                    fetchProjects={fetchProjects}
                  />
                ) : (
                  <Column
                    key={backlogColumn?.id}
                    column={backlogColumn}
                    moveCard={moveCard}
                    backlog={true}
                    onDoubleClick={handleDoubleClick}
                    view={view}
                    selectedWeek={selectedWeek} // Pass selectedWeek to Column
                    setProjects={setProjects}
                    projects={projects}
                    fetchProjects={fetchProjects}
                  />
                )}
              </div>
            </div>
            <div className="p-4 pb-0 flex flex-col bg-[#cd5a91] min-h-[100vh] w-[80vw]">
              <div className="flex justify-between mb-4">
                {/* <button
                  onClick={handlePrevious}
                  className={`bg-[#2e0d2a] text-white text-[14px] px-3 py-2 rounded ${
                    view === "week" ? "invisible" : "invisible"
                  }`}
                  disabled={startWeek <= 1}
                >
                  <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                </button> */}
                <FilterControls
                  filter={filter}
                  projectTypeOption={projectTypeOption}
                  minProjectSize={minProjectSize}
                  maxProjectSize={maxProjectSize}
                  values={values}
                  setValues={setValues}
                  handleFilterChange={handleFilterChange}
                  handleClearFilters={handleClearFilters}
                  handleMinSizeChange={handleMinSizeChange}
                  handleMaxSizeChange={handleMaxSizeChange}
                  handleBackToWeekView={handleBackToWeekView}
                  view={view}
                />
                {/* <button
                  onClick={handleNext}
                  className={`bg-[#2e0d2a] text-white text-[14px] px-3 py-2 rounded ${
                    view === "week" ? "invisible" : "visible"
                  }`}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
                </button> */}
              </div>
              {view === "week" ? (
                <div className="w-full h-[88vh] space-x-4">
                  <SlickSlider {...settings}>
                    {columns.map((column) => (
                      <Column
                        key={column?.id}
                        column={column}
                        moveCard={moveCard}
                        onDoubleClick={handleDoubleClick}
                        setView={setView}
                        onWeekTitleClick={handleWeekTitleClick}
                        filterSearch={filter?.searchQuery}
                        setProjects={setProjects}
                        fetchProjects={fetchProjects}
                        handleConfirmation={handleConfirmation}
                        setOpen={setOpen}
                      />
                    ))}
                  </SlickSlider>
                </div>
              ) : (
                <MainComponent
                  selectedWeek={selectedWeek}
                  startDate={startDate}
                  startWeek={startWeek}
                  weekDayProject={weekDayProject}
                  moveCard={moveCard}
                  handleDoubleClick={handleDoubleClick}
                  extractWeekNumber={extractWeekNumber}
                  getFiscalWeekStartDate={getFiscalWeekStartDate}
                  filterSearch={filter?.searchQuery}
                  dayViewSettings={dayViewSettings}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  setProjects={setProjects}
                  projects={projects}
                  fetchProjects={fetchProjects}
                  setOpen={setOpen}
                  handleEditChange={handleEditChange}
                />
              )}
            </div>
          </>
        )}
      </div>
      {editCard && (
        <EditCardDialog
          editCard={editCard}
          open={editOpen}
          handleEditClose={handleEditClose}
          handleEditChange={handleEditChange}
          handleDateChange={handleDateChange}
        />
      )}
      {parentShiftOpen && (
        <ConfirmationPopup
          editCard={editCard}
          open={parentShiftOpen}
          handleEditClose={() => setParentShiftOpen(false)}
          onConfirm={moveConfirmation}
          message="If you change the fiscal week then all child projects will be deleted. Are you sure you want to move?"
        />
      )}
    </DndProvider>
  );
};

const calculateProjectSize = (paidAmount, dueAmount) =>
  Number(paidAmount) + Number(dueAmount);

const formatFiscalWeekTitle = (weekNumber, date) => {
  const formattedDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return `${weekNumber} (${formattedDate})`;
};

const getFiscalWeekStartDate = (startDate, weekOffset) => {
  const start = new Date(startDate);
  const newStart = new Date(start.setDate(start.getDate() + weekOffset * 7));
  return newStart;
};

const extractWeekNumber = (fiscalWeek) => {
  if (!fiscalWeek) return "";
  const weekNumberMatch = fiscalWeek.match(/^(\d+)-?\d*/);
  return weekNumberMatch ? weekNumberMatch[1] : "";
};

const extractYear = (fiscalWeek) => {
  if (!fiscalWeek) return "";
  const dateMatch = fiscalWeek.match(/\((\d{1,2} \w+ (\d{4}))\)/);
  return dateMatch ? dateMatch[2] : "";
};

const getFiscalWeekNumber = (startDate, date) => {
  const start = new Date(startDate);
  const diff = Math.floor((date - start) / (7 * 24 * 60 * 60 * 1000));
  return diff + 1;
};

const getCurrentFiscalWeekNumber = () => {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  return getFiscalWeekNumber(startOfYear, today);
};

const getCurrentFiscalWeekStartDate = () => {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const currentWeekNumber = getFiscalWeekNumber(startOfYear, today);
  return getFiscalWeekStartDate(startOfYear, currentWeekNumber - 1);
};

export default HomePage;
