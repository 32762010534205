// HomePageSkeleton.js
import React from "react";
import { Box, Skeleton, Grid, Typography, Paper } from "@mui/material";

const SkeletonLoader = () => {
  return (
    <Box
      sx={{
        padding: "16px",
        width: "100vw",
        height: "100vh",
        display: "flex",
        backgroundColor: "#cd5a91"
      }}
    >
      {/* Left Section with Fixed Column */}
      <Box sx={{ width: "20vw", paddingRight: "16px", borderRadius: "15px" }}>
        <Skeleton variant="text" width={150} height={40} sx={{ mb: 2 }} />
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          {Array.from(new Array(5)).map((_, cardIndex) => (
            <Skeleton
              key={cardIndex}
              variant="rectangular"
              width="100%"
              height={105}
              sx={{ mb: 2 }}
            />
          ))}
        </Paper>
      </Box>

      {/* Right Section with Multiple Columns */}
      <Box sx={{ width: "80vw" }}>
        <Grid container spacing={2}>
          {/* Filters Skeleton */}
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Skeleton variant="rectangular" width={200} height={40} />
              <Skeleton variant="rectangular" width={230} height={40} />
              <Skeleton variant="rectangular" width={230} height={40} />
              <Skeleton variant="rectangular" width={100} height={40} />
            </Box>
          </Grid>

          {/* Columns Skeleton */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", gap: 4, overflowX: "auto" }}
          >
            {Array.from(new Array(5)).map((_, index) => (
              <Box
                key={index}
                sx={{
                  minWidth: "200px",
                  backgroundColor: "#f1f2f4",
                  padding: "6px",
                  borderRadius: "15px"
                }}
              >
                <Skeleton
                  variant="text"
                  width={150}
                  height={40}
                  sx={{ mb: 2 }}
                />
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  {Array.from(new Array(5)).map((_, cardIndex) => (
                    <Skeleton
                      key={cardIndex}
                      variant="rectangular"
                      width="100%"
                      height={80}
                      sx={{ mb: 2 }}
                    />
                  ))}
                </Paper>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SkeletonLoader;
