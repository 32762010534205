const red = [
  "Yes w/ Open Questions",
  "Yes. Not Yet Started",
  "Yes. Reviewed w/ Open Questions",
  "Reviewed w/ Open Questions",
  "Invoice : Reviewed w/ Open Questions",
  "Ops : Reviewed w/ Open Questions",
  "PENDING"
];
const yellow = ["Yes. In Process", "Yes. Not Yet Reviewed", "Not Yet Reviewed"];
const green = [
  "Yes. Confirmed",
  "Yes. Complete",
  "Yes. Reviewed. Good to go.",
  "Reviewed and Approved",
  "Invoice : Reviewed and Approved",
  "Ops : Reviewed and Approved",
  "DONE"
];

// const blue = ["Not Needed", "No existing file"];
const gray = ["Not Needed", "No existing file"];

export const blueOP = "#0408f833";
export const redOP = "#ff004136";
export const greenOP = "#04f82938";
export const yellowOP = "#f8d40447";
export const grayOP = "#09090929";

export const blueWithOP = "#0408f8c4";
export const redWithOP = "#ff0041ed";
export const greenWithOP = "#04a81d";
export const yellowWithOP = "#debe06ed";
export const grayWithOP = "#09090973";
export const defaultGray = "#ccc";

export const getColor = (ops) => {
  if (red?.includes(ops)) {
    return redOP;
  } else if (yellow?.includes(ops)) {
    return yellowOP;
  } else if (green?.includes(ops)) {
    return greenOP;
  } else if (gray?.includes(ops)) {
    return defaultGray;
  } else {
    return "white";
  }
};

export const getColorForButton = (ops) => {
  if (red?.includes(ops)) {
    return redWithOP;
  } else if (yellow?.includes(ops)) {
    return yellowWithOP;
  } else if (green?.includes(ops)) {
    return greenWithOP;
  } else if (gray?.includes(ops)) {
    return defaultGray;
  } else {
    return redWithOP;
  }
};
