import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let customDotStyles = `
    .slick-dots {
      bottom: 0px;
    }

    .slick-dots li button:before {
      color: #ffffff;
      font-size: 20px !important;
    }


    .slick-slider{
        height:100%
    }
    .slick-list{
        height:100%
    }
    .slick-prev{
      left:-21px
    }
  
    .slick-next{
      right:-21px;
      display:none
    }
  `;

const CustomNextArrow = ({ onClick, handleNext }) => {
  return (
    <div
      className="slick-arrow slick-next"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "100px",
        backgroundColor: "#333",
        borderRadius: "4px",
        color: "#fff",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
      }}
      onClick={(e) => {
        handleNext();
        if (onClick) onClick(e);
      }}
    >
      &#9654; {/* Right arrow icon */}
    </div>
  );
};

const CustomPrevArrow = ({ onClick, handlePrevious }) => {
  return (
    <div
      className="slick-arrow slick-prev"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "100px",
        backgroundColor: "#333",
        borderRadius: "4px",
        color: "#fff",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
      }}
      onClick={(e) => {
        handlePrevious();
        if (onClick) onClick(e);
      }}
    >
      &#9664; {/* Left arrow icon */}
    </div>
  );
};

const DayViewSlickSlider = ({
  children,
  infinite = false,
  slidesToShow = 1,
  slidesToScroll = 1,
  handleAfterChange,
  initialSlide = 0,
  beforeChange,
  onMouseDown,
  onMouseUp,
  handlePrevious,
  handleNext
}) => {
  const sliderSettings = {
    dots: false,
    infinite: infinite,
    speed: 500,
    afterChange: handleAfterChange,
    initialSlide,
    slidesToShow: 1,
    arrows: true,
    beforeChange: beforeChange,
    nextArrow: <CustomNextArrow handleNext={handleNext} />,
    prevArrow: <CustomPrevArrow handlePrevious={handlePrevious} />
    // fade: true,
    // lazyLoad: true
  };

  return (
    <>
      <style>{customDotStyles}</style>
      <div
        style={{ height: "100%" }}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <Slider {...sliderSettings}>{children}</Slider>
      </div>
    </>
  );
};

export default DayViewSlickSlider;
