import * as HttpService from "./http.service";
import {
  ADD_CLONE_PROJECT,
  ADD_OPS_RESULT_DATA_TO_ZOHO,
  BULK_DELETE_CLONE_PROJECT,
  DELETE_CLONE_PROJECT,
  DELETE_OPEN_QUESTION,
  DELETE_TRANSITION,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERS_LIST,
  GET_OPS_RESULTING_DATA_BY_ID,
  GET_USER_BY_ID,
  TEMP_API,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOM_NOTE,
  UPDATE_CUSTOM_NOTES,
  UPDATE_INVOICE,
  UPDATE_INVOICE_PRODUCTS,
  UPDATE_OPEN_QUESTION,
  UPDATE_OPS_SCHEDULING,
  UPDATE_PROJECT_STATUS,
  UPDATE_QUESTION,
  UPDATE_ROOM_NAME_IN_ZOHO,
  UPDATE_TRANSITION,
  UPLOAD_IMAGE
} from "./url.service";

export const getOpsResultingData = (id) => {
  return HttpService.getWithOutAuth(GET_OPS_RESULTING_DATA_BY_ID(id));
};

export const pushOpsResultDatatoZoho = (data, id) => {
  return HttpService.postWithOutAuth(ADD_OPS_RESULT_DATA_TO_ZOHO(id), data);
};

export const getUserById = (id) => {
  return HttpService.getWithOutAuth(GET_USER_BY_ID(id));
};
export const getCustomerById = (id) => {
  return HttpService.getWithAuth(GET_CUSTOMER(id));
};
export const getAllCustomers = (
  page,
  size,
  filter1,
  filter2,
  searchQuery,
  order,
  currentTab
) => {
  return HttpService.getWithAuth(
    GET_CUSTOMERS_LIST(
      page,
      size,
      filter1,
      filter2,
      searchQuery,
      order,
      currentTab
    )
  );
};

export const updateQuestion = (id, quesObj) => {
  return HttpService.putWithAuth(UPDATE_QUESTION(id), quesObj);
};
export const updateInvoiceProduct = (id, prodDetails) => {
  return HttpService.putWithAuth(UPDATE_INVOICE_PRODUCTS(id), prodDetails);
};
export const updateNotes = (id, note) => {
  return HttpService.putWithAuth(UPDATE_CUSTOM_NOTES(id), note);
};
export const updateInvoice = (customerId, invoiceObj) => {
  return HttpService.putWithAuth(UPDATE_INVOICE(customerId), invoiceObj);
};
export const updateProjectStatus = (customerId, projectStatus) => {
  return HttpService.patchWithAuth(
    UPDATE_PROJECT_STATUS(customerId),
    projectStatus
  );
};
export const updateCustomNote = (customerId, customNoteObj) => {
  return HttpService.putWithAuth(UPDATE_CUSTOM_NOTE(customerId), customNoteObj);
};
export const updateOpenQuestion = (customerId, customNoteObj) => {
  return HttpService.putWithAuth(
    UPDATE_OPEN_QUESTION(customerId),
    customNoteObj
  );
};
export const uploadImage = (img) => {
  return HttpService.postWithAuthImg(UPLOAD_IMAGE(), img);
};
export const deleteOpenQuesion = (customerId, questionId) => {
  return HttpService.deleteWithAuth(
    DELETE_OPEN_QUESTION(customerId, questionId)
  );
};
export const deleteTransition = (customerId, questionId) => {
  return HttpService.deleteWithAuth(DELETE_TRANSITION(customerId, questionId));
};

export const updateTransition = (customerId, transitionObj) => {
  return HttpService.putWithAuth(UPDATE_TRANSITION(customerId), transitionObj);
};

// New Apis

export const getCustomers = (startDate, endDate) => {
  return HttpService.getWithAuth(GET_CUSTOMERS(startDate, endDate));
};

export const updateCustomer = (customerId, customObj) => {
  return HttpService.patchWithAuth(UPDATE_CUSTOMER(customerId), customObj);
};
export const addCloneProject = (customerId, customObj) => {
  return HttpService.putWithAuth(ADD_CLONE_PROJECT(customerId), customObj);
};
export const deleteCloneProject = (parentId, customerId, customObj) => {
  return HttpService.putWithAuth(
    DELETE_CLONE_PROJECT(parentId, customerId),
    customObj
  );
};
export const bulkDeleteCloneProject = (parentId, customObj) => {
  return HttpService.putWithAuth(
    BULK_DELETE_CLONE_PROJECT(parentId),
    customObj
  );
};
export const updateOpsScheduling = (parentId, customerId, customObj) => {
  return HttpService.patchWithAuth(
    UPDATE_OPS_SCHEDULING(parentId, customerId),
    customObj
  );
};
