import React from "react";
import { Typography } from "@mui/material";
import DayColumn from "../DayColumn";

const TeamDayColumns = ({
  teams,
  selectedWeek,
  startDate,
  startWeek,
  weekDayProject,
  moveCard,
  handleDoubleClick,
  extractWeekNumber,
  getFiscalWeekStartDate,
  filterSearch,
  setProjects,
  projects,
  fetchProjects,
  setOpen,
  handleEditChange
}) => {
  // Calculate the start date of the selected week
  const weekStartDate = getFiscalWeekStartDate(
    startDate,
    extractWeekNumber(selectedWeek) - startWeek
  );

  // Get the current year from the start date
  const currentYear = weekStartDate.getFullYear();
  return (
    <div className="flex-1 flex flex-col overflow-x-auto space-y-4">
      {teams.map((team) => (
        <div key={team} className="flex flex-col">
          <div className="w-[150px] ml-1 rounded text-center flex items-center justify-center bg-gray-200">
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {team}
            </Typography>
          </div>
          <div className="grid grid-cols-6 gap-4">
            {Array.from({ length: 6 }).map((_, index) => {
              const currentDayDate = new Date(weekStartDate);
              currentDayDate.setDate(currentDayDate.getDate() + index);

              // Break loop if we reach the end of the year
              if (currentDayDate.getFullYear() !== currentYear) return null;

              // Format the date to display
              const formattedDate = currentDayDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric"
              });

              const dayName = currentDayDate.toLocaleDateString("en-GB", {
                weekday: "short" // Get the day name (Mon, Tue, etc.)
              });

              // Filter projects based on the day, team, and search query
              const filteredProjects = weekDayProject
                ?.filter(
                  (project) =>
                    project?.actual_start_day === dayName &&
                    project?.assigned_team === team &&
                    project?.parent_id
                )
                ?.filter(
                  (project) =>
                    project.name &&
                    project.name
                      .toLowerCase()
                      .includes(filterSearch?.toLowerCase() || "")
                );
              return (
                <DayColumn
                  key={dayName + team}
                  day={dayName} // Pass the day name
                  selectedWeek={selectedWeek}
                  projects={filteredProjects}
                  handleDrop={moveCard} // Pass the function here
                  onDoubleClick={handleDoubleClick}
                  formattedDate={formattedDate}
                  team={true}
                  teamName={team}
                  setProjects={setProjects}
                  allProjects={projects}
                  fetchProjects={fetchProjects}
                  setOpen={setOpen}
                  handleEditChange={handleEditChange}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamDayColumns;
