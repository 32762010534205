import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    boards: [
        { id: 1, title: 'Project 1', cards: [{ id: 1, text: 'Sample Card 1' }] },
        { id: 2, title: 'Project 2', cards: [{ id: 2, text: 'Sample Card 2' }] },
    ],
};

const boardSlice = createSlice({
    name: 'boards',
    initialState,
    reducers: {
        addCard: (state, action) => {
            const { boardId, card } = action.payload;
            const board = state.boards.find((b) => b.id === boardId);
            board.cards.push(card);
        },
        // Add more reducers as needed
    },
});

export const { addCard } = boardSlice.actions;

export default boardSlice.reducer;
