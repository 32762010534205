import React, { useState, useRef, useEffect } from "react";
import { useDrop } from "react-dnd";
import DraggableCard from "./DraggableCard";
import WeekPopup from "./WeekPopup";
import SeatchNoteField from "../Fields/NoteField";
import SearchNoteField from "../Fields/NoteField";
import NoteField from "../Fields/NoteField";

const calculateTotals = (projects) => {
  const totalProjectSize = projects?.reduce(
    (sum, project) => sum + Number(project["project_size"] || 0),
    0
  );
  const totalPaidAmount = projects?.reduce(
    (sum, project) => sum + Number(project["paid_amount"] || 0),
    0
  );
  const totalDueAmount = projects?.reduce(
    (sum, project) => sum + Number(project["due_amount"] || 0),
    0
  );

  return { totalProjectSize, totalPaidAmount, totalDueAmount };
};

const formatTitleWithComma = (title) => {
  return title?.replace(/(\d{2} \w{3}) (\d{4})/, "$1, $2");
};

const Column = ({
  column,
  moveCard,
  backlog = false,
  onDoubleClick,
  setView,
  view,
  onWeekTitleClick,
  filterSearch = "",
  setProjects,
  projects,
  fetchProjects,
  handleConfirmation,
  setOpen
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "CARD",
    drop: (item) => {
      if (item?.FW !== column?.title) {
        if (item?.project?.clones?.length > 0) {
          setOpen(true);
          handleConfirmation({ ...item, FW: column?.id });
        } else {
          moveCard(item.id, column.id);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });
  console.log("filterSeach-", filterSearch, backlog, column);

  const { totalProjectSize, totalPaidAmount, totalDueAmount } = calculateTotals(
    column.projects
  );

  const [searchQuery, setSearchQuery] = useState("");

  // Filter projects based on the search query, with null checks
  const filteredProjects = backlog
    ? column.projects?.filter(
        (project) =>
          project.name &&
          project.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : column.projects?.filter(
        (project) =>
          project.name &&
          project.name.toLowerCase().includes(filterSearch.toLowerCase())
      );

  const handleTitleClick = () => {
    setView("day");
    onWeekTitleClick(column?.title);
  };

  return (
    <>
      <div
        ref={drop}
        className={` relative flex-1 bg-[#f1f2f4] text-[12px] text-black px-1 py-4 ${
          backlog ? "ml-0 h-full" : "h-[86vh] mx-1 my-2 rounded-xl"
        } shadow-md ${backlog && "h-full !bg-[#ac4b79] "} ${
          isOver ? "border-2 border-dashed border-blue-500" : ""
        }`}
        style={{ minWidth: "200px", flexBasis: "0" }}
      >
        <div className="flex flex-col h-full">
          {backlog && (
            <NoteField
              question={"Search"}
              value={searchQuery}
              handleNoteBlur={(e) => setSearchQuery(e.target.value)}
              type="text"
              event="onChange"
            />
          )}
          <div className="flex-1 ">
            <div className="py-2.5">
              <h2
                onClick={!backlog ? handleTitleClick : null}
                className={`cursor-pointer absolute top-1 z-50  ${
                  backlog
                    ? "text-[16px] text-black bg-white"
                    : " backdrop-blur-xl bg-opacity-50 text-[14px]"
                } font-bold px-3  rounded-lg`} // Add bg-white, bg-opacity-50, and rounded-lg here
              >
                {!backlog && "FW - "}
                {formatTitleWithComma(column?.title)}
              </h2>
            </div>

            <div
              className="overflow-y-auto"
              style={{
                height: backlog ? "calc(100vh - 180px)" : "calc(100vh - 250px)"
              }}
            >
              {filteredProjects?.map((project) => {
                console.log("project22", project);
                return (
                  <DraggableCard
                    key={project?.id}
                    id={project?.id}
                    customerName={project["name"]}
                    projectType={project["project_type"]}
                    projectSize={project["project_size"]}
                    estimatedStartDate={project["estimated_start_date"]}
                    onDoubleClick={onDoubleClick}
                    state={project["state"]}
                    delivery_status={project["delivery_status"]}
                    ops_overall_status={project["ops_overall_status"]}
                    project={project}
                    projects={projects}
                    dragType={"CARD"}
                    fetchProjects={fetchProjects}
                  />
                );
              })}
            </div>
          </div>
          <div
            className="absolute bottom-0 left-0 w-full p-2 bg-gray-800 text-white overflow-x-auto"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="flex flex-col space-y-1 text-[13px] font-semibold">
              <div className="flex justify-between items-center border-b border-gray-700 pb-1">
                <span>Project Size:</span>
                <span className="text-lg font-bold">
                  {totalProjectSize?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-gray-700 pb-1">
                <span className="text-green-300">Paid:</span>
                <span className="text-green-500 font-bold">
                  {totalPaidAmount?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-red-300">Due:</span>
                <span className="text-red-500 font-bold">
                  {totalDueAmount?.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Column;
